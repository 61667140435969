import { HIDE_INTERCOM_ROUTES, INTERCOM_APP_ID } from "constants/intercom";
import { useUser } from "contexts/UserContext";
import { useSubscription } from "contexts/subscription-context";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import queryIntercomHmac from "services/queryIntercomHmac";
import onDocumentReady from "utils/onDocumentReady";

/**
 * intercom API docs reference
 * @see https://developers.intercom.com/installing-intercom/docs/intercom-javascript
 */
function createIntercomScriptTag() {
  const d = document;
  const i: any = function () {
    i.c(arguments);
  };
  i.q = [];
  i.c = function (args) {
    i.q.push(args);
  };
  (window as any).Intercom = i;
  const l = function () {
    const s = d.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.src = `https://widget.intercom.io/widget/${INTERCOM_APP_ID}`;
    const x = d.getElementsByTagName("script")[0];
    x.parentNode?.insertBefore(s, x);
  };
  onDocumentReady(l);
}

async function setupIntercom(
  nextSettings: Record<string, string | boolean | number> = {},
) {
  let settings: Record<string, string | boolean | number> = {
    app_id: INTERCOM_APP_ID,
    ...nextSettings,
  };

  (window as any).intercomSettings = settings;

  const ic = (window as any).Intercom;
  if (typeof ic === "function") {
    ic("reattach_activator");
    ic("update", (window as any).intercomSettings);
  } else {
    createIntercomScriptTag();
  }
}

async function restartIntercom(
  user: User | null | undefined,
  planType: string | null | undefined,
  nextSettings: Record<string, string | boolean | number> = {},
) {
  let settings: Record<string, string | boolean | number> = {
    app_id: INTERCOM_APP_ID,
    ...nextSettings,
  };

  if (user) {
    settings = {
      ...settings,
      // TODO: this is an example of usage of intercom, remove this line when u know the fields to collect
      user_json: JSON.stringify(user),
      name: `${user.first_name} ${user.last_name}`,
      user_type: user.user_type || "",
      profile_approved_to_use_the_platform:
        user.profile_approved_to_use_the_platform,
      plan_type: planType || "",
      plan_period: user?.orchid_pro_subscription?.sub_period || "",
    };

    try {
      const hmac = await queryIntercomHmac();
      settings = {
        ...settings,
        user_id: hmac.unique_id,
        user_hash: hmac.hmac,
      };
    } catch (error) {
      if (process.env.NODE_ENV !== "development") {
        console.log(error);
      }
    }
  }

  const needsShutdown =
    // logged out
    settings.user_id === undefined ||
    // logged in to another user
    (window as any).intercomSettings?.user_id !== settings.user_id;

  (window as any).intercomSettings = settings;

  const ic = (window as any).Intercom;
  if (typeof ic === "function") {
    if (!needsShutdown) {
      ic("reattach_activator");
      ic("update", (window as any).intercomSettings);
    } else {
      ic("shutdown");
      ic("boot", (window as any).intercomSettings);
    }
  } else {
    createIntercomScriptTag();
  }
}

async function shutdownIntercom() {
  const ic = (window as any).Intercom;
  if (typeof ic === "function") {
    ic("shutdown");
  } else {
    return;
  }
}
const Intercom = () => {
  const { user } = useUser();
  const { pathname } = useLocation();
  const { planType } = useSubscription();

  useEffect(() => {
    setupIntercom();
    // eslint-disable-next-line
  }, []);

  // pathname
  useEffect(() => {
    if (
      HIDE_INTERCOM_ROUTES.some((el) => pathname.startsWith(el)) ||
      pathname.endsWith("/attachments") ||
      pathname.endsWith("/client-info")
    ) {
      shutdownIntercom();
    } else {
      restartIntercom(user, planType, {
        hide_default_launcher: false,
      });
    }
  }, [user, pathname, planType]);

  return null;
};

export default Intercom;
