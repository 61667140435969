import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Form, InputGroup, Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { AmplitudePageName } from "utils/amplitude";
import { useTimeOnPageContext } from "contexts/time-on-page";
import { usePhonePortraitContext } from "contexts/phone-portrait-context";
import LazyImage from "components/lazy-image";
import CustomAccordion from "components/custom-accordion/CustomAccordion";
import SCPhoneView from "./SCPhoneView";
import Footer from "components/footer/Footer";
import BookADemo from "components/Subscription/pricing-page/book-a-demo/BookADemo";
import RangeSlider from "./RangeSlider";

import getClasses from "utils/getClasses";
import styles from "./SavingsCalculator.module.scss";

const AMPLITUDE_PAGE_NAME: AmplitudePageName = "savings-calculator-page";

const LANDING = "/img/savings-calculator/landing.png";

export const questions = [
  {
    id: "0",
    question: "Your Money Saved",
    answer: `<p>We refer to the difference between Orchid’s credit card processing fees versus those same processing fees of industry heavyweight SimplePractice: </p>
      <p><b>Orchid:</b> as low as 1.99% / transaction <i>(the lowest processing fees on the market!)</i>
      <br />
      <b>SimplePractice:</b> 3.15% / transaction</p>
      <p><i>You save 1.16% per transaction.</i> <b>For every $10,000, you save $116</b> 🙂</p>
      `,
  },
  {
    id: "1",
    question: "Your Time Saved",
    answer: `<p>Based on internal research & customer testimonials, we’ve found that Orchid’s all-in-one EHR saves you an average of 30 minutes per client per week. </p>
       <p><b>Our equation:</b> <i>30 minutes * number of clients per week * 50 weeks per year = the time that’s all yours again</i>.</p>`,
  },
  {
    id: "2",
    question: "Healthcare Industry Savings",
    answer: `
      <p>When you’re able to accomplish more in less time, you’re not only helping to improve your own wellbeing; you’re also helping to reduce the burden on the healthcare industry’s bloated administrative costs. </p>
      <p><b>Our equation:</b> <i>Your Hours Saved Annually * Your Hourly Rate = Total $ You Save for the Industry Annually</i></p>
      `,
  },
];

const SavingsCalculator = () => {
  const { setPage } = useTimeOnPageContext();
  useEffect(() => setPage(AMPLITUDE_PAGE_NAME), [setPage]);

  const { isPhonePortrait } = usePhonePortraitContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [showResult, setShowResult] = useState(false);

  // num of clients
  const [value, setValue] = useState({ min: 0, max: 51 });
  const [rate, setRate] = useState<string>("");

  const containerRef = useRef<HTMLDivElement | null>(null);

  return (
    <>
      <Helmet>
        <title>Orchid’s EHR Cost Savings</title>
        <meta
          name="description"
          content="Use our simple EHR cost savings calculator to find out how much time and money you’ll save by switching to Orchid for your private practice."
        />
        <meta property="og:title" content="Orchid’s EHR Cost Savings" />
        <meta
          property="og:description"
          content="Use our simple EHR cost savings calculator to find out how much time and money you’ll save by switching to Orchid for your private practice."
        />

        <meta name="twitter:title" content="Orchid’s EHR Cost Savings" />
        <meta
          name="twitter:description"
          content="Use our simple EHR cost savings calculator to find out how much time and money you’ll save by switching to Orchid for your private practice."
        />
      </Helmet>
      {isPhonePortrait ? (
        <SCPhoneView />
      ) : (
        <div>
          <div className={styles["landing"]}>
            <Container
              className={getClasses(
                styles["container"],
                styles["landing__left"],
              )}
            >
              <div className={styles["landing__title"]}>
                Save More When You <br />
                Switch to Orchid
              </div>
              <div className={styles["landing__text"]}>
                <p className={"mb-0"}>
                  Orchid saves you so much time, money, and frustration, we made
                  you this
                </p>
                <p>
                  handy tool to calculate exactly how much time & money you’ll
                  save.*
                </p>
              </div>
              <div className={styles["landing__text"]}>
                <span className={styles["landing__star"]}>*</span> Frustration
                saved = priceless.
              </div>
            </Container>
            <div className={styles["landing__right"]}>
              <LazyImage src={LANDING} width={854} height={"auto"} />
            </div>
          </div>

          <div className={styles["calculator"]}>
            <Container className={styles["calculator__container"]}>
              <Row className={styles["calculator__row"]}>
                <Col className={styles["calculator__left"]}>
                  <div className={styles["calculator__title"]}>
                    Orchid EHR <br />
                    Cost Savings Calculator
                  </div>
                </Col>
                <Col ref={containerRef} className={styles["calculator__right"]}>
                  {!showResult ? (
                    <div className={styles["calculator__card"]}>
                      <div className={styles["calculator__text"]}>
                        # of clients you see each week:
                      </div>
                      <div
                        className={styles["calculator__range-slider-container"]}
                      >
                        <span className={styles["calculator__zero"]}>0</span>{" "}
                        <div className={styles["calculator__range-slider"]}>
                          <RangeSlider
                            min={0}
                            max={50}
                            step={1}
                            value={value}
                            onChange={setValue}
                          />{" "}
                        </div>
                        <span className={styles["calculator__fifty"]}>
                          50 Clients
                        </span>
                      </div>

                      <div className={styles["calculator__text"]}>
                        Your hourly rate:
                      </div>
                      <div className={"d-flex align-items-center"}>
                        <InputGroup
                          className={styles["calculator__form-control"]}
                        >
                          <InputGroup.Prepend>
                            <InputGroup.Text>$</InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            placeholder={"75"}
                            type="text"
                            value={rate || ""}
                            onChange={(e) => setRate(e.target.value)}
                          />
                        </InputGroup>{" "}
                        <span className={"ml-2"}>/ hr</span>
                      </div>
                      <hr className={styles["calculator__hr"]} />
                      <div className={"d-flex justify-content-center"}>
                        <Button
                          className={styles["calculator__action"]}
                          onClick={() => {
                            setShowResult(true);
                          }}
                        >
                          See Your Saving Instantly
                        </Button>
                      </div>
                      <div className={styles["calculator__small-text"]}>
                        <i>
                          Don’t worry: We never (ever) save or sell this info.
                        </i>
                      </div>
                    </div>
                  ) : (
                    <div
                      className={getClasses(
                        styles["calculator__card"],
                        styles["calculator__result"],
                      )}
                    >
                      <div className={styles["calculator__result-title"]}>
                        Your Saving are Ready!
                      </div>
                      <div className={styles["calculator__result-small-text"]}>
                        Based on what you entered...
                      </div>
                      <div className={styles["calculator__result-text"]}>
                        Orchid will:
                      </div>
                      <div className={styles["calculator__result-card"]}>
                        <div className={styles["calculator__result-sub-title"]}>
                          save you
                        </div>
                        <div className={styles["calculator__saving-line"]}>
                          up to{" "}
                          <span className={styles["calculator__result-title"]}>
                            $
                            {(
                              ((value.min * 50 * parseInt(rate || "0")) /
                                1000) *
                              11.6
                            ).toLocaleString()}
                          </span>{" "}
                          each and every year.
                        </div>
                      </div>

                      <div className={styles["calculator__result-card"]}>
                        <div className={styles["calculator__result-sub-title"]}>
                          help you reclaim
                        </div>
                        <div className={styles["calculator__saving-line"]}>
                          up to{" "}
                          <span className={styles["calculator__result-title"]}>
                            {value.min !== 0 ? value.min * 0.5 * 50 : "0"} hours
                          </span>{" "}
                          of your time annually.
                        </div>
                      </div>

                      <div className={styles["calculator__result-card"]}>
                        <div className={styles["calculator__result-sub-title"]}>
                          & even save the healthcare industry
                        </div>
                        <div className={styles["calculator__saving-line"]}>
                          up to{" "}
                          <span className={styles["calculator__result-title"]}>
                            $
                            {(
                              value.min *
                              25 *
                              parseInt(rate || "0")
                            ).toLocaleString()}
                          </span>{" "}
                          of your time annually.
                        </div>
                      </div>
                      <p className={styles["calculator__only-when"]}>
                        ...but only when
                      </p>
                      <div className={styles["calculator__result-sub-title"]}>
                        you start your free 30-day trial today.
                      </div>
                      <hr className={styles["calculator__hr"]} />
                      <div className={styles["calculator__action-group"]}>
                        <Button
                          size={"lg"}
                          variant={"outline-primary"}
                          onClick={() => {
                            setShowResult(false);
                            if (containerRef.current) {
                              containerRef.current.scrollIntoView();
                            }
                          }}
                        >
                          Retry Calculation
                        </Button>
                        <Button
                          size={"lg"}
                          className={getClasses(
                            "ml-3",
                            styles["calculator__action"],
                          )}
                          as={Link}
                          to={"/pricing"}
                          target={"_blank"}
                          rel={"noopener noreferrer"}
                        >
                          Pick your Plan to Start Saving
                        </Button>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </Container>
          </div>
          <Container className={styles["container"]}>
            <div className={styles["faq"]}>
              <h1 className={getClasses(styles["bottom-56"], styles["title"])}>
                FAQ: <br />
                Wondering how we calculated your EHR cost saving results?
              </h1>
              {questions.map(({ id, question, answer }, idx) => {
                return (
                  <CustomAccordion
                    key={id}
                    id={id}
                    index={idx}
                    question={question}
                    answer={answer}
                    amplitudePageName={AMPLITUDE_PAGE_NAME}
                  />
                );
              })}
            </div>
          </Container>
          <BookADemo amplitudePageName={AMPLITUDE_PAGE_NAME} />
          <Footer AMPLITUDE_PAGE_NAME={AMPLITUDE_PAGE_NAME} />
        </div>
      )}
    </>
  );
};

export default SavingsCalculator;
