import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  NewNoteOrFormTemplate,
  NoteOrFormTemplate,
  TemplateType,
} from "notes-and-forms-v2/NotesAndForms.types";
import { convertTemplateToV1 } from "notes-and-forms-v2/util/convertTemplateToV1";
import { convertTemplateToV2 } from "notes-and-forms-v2/util/convertTemplateToV2";
import { apiHostAndPath } from "utils/api";

export type SaveTemplateMutationPayload =
  | {
      requestType: "create";
      template: NewNoteOrFormTemplate;
    }
  | {
      requestType: "update";
      template: NoteOrFormTemplate;
    };

type UnparsedFormSchema = Omit<
  FormSchema,
  "form_json_schema" | "form_ui_schema"
> & {
  form_json_schema: string;
  form_ui_schema: string;
};

type UnparsedNoteSchema = Omit<
  NoteSchema,
  "note_json_schema" | "note_ui_schema"
> & {
  note_json_schema: string;
  note_ui_schema: string;
};

const getCreateNoteSchemaQuery = (template: NewNoteOrFormTemplate) => {
  const { jsonSchema, uiSchema } = convertTemplateToV1(template);

  const body: Pick<
    UnparsedNoteSchema,
    "title" | "note_json_schema" | "note_ui_schema"
  > = {
    title: template.title,
    note_json_schema: JSON.stringify(jsonSchema),
    note_ui_schema: JSON.stringify(uiSchema),
  };

  return {
    url: apiHostAndPath("/api/notes_secure_data/v1/notes/schema/"),
    method: "POST",
    body,
  };
};

const getUpdateNoteSchemaQuery = (template: NoteOrFormTemplate) => {
  const { jsonSchema, uiSchema } = convertTemplateToV1(template);

  const body: Omit<UnparsedNoteSchema, "create_timestamp" | "form_type"> = {
    title: template.title,
    note_schema_id: template.id,
    note_schema_revision: template.revision,
    note_json_schema: JSON.stringify(jsonSchema),
    note_ui_schema: JSON.stringify(uiSchema),
  };
  return {
    url: apiHostAndPath(
      `/api/notes_secure_data/v1/notes/schema/${template.id}`,
    ),
    method: "PUT",
    body,
  };
};

const getCreateFormSchemaQuery = (template: NewNoteOrFormTemplate) => {
  const { jsonSchema, uiSchema } = convertTemplateToV1(template);

  const body: Pick<
    UnparsedFormSchema,
    "title" | "form_json_schema" | "form_ui_schema" | "form_type"
  > = {
    title: template.title,
    form_type: "custom",
    form_json_schema: JSON.stringify(jsonSchema),
    form_ui_schema: JSON.stringify(uiSchema),
  };

  return {
    url: apiHostAndPath("/api/notes/v1/forms/schema/"),
    method: "POST",
    body,
  };
};

const getUpdateFormSchemaQuery = (template: NoteOrFormTemplate) => {
  const { jsonSchema, uiSchema } = convertTemplateToV1(template);

  const body: Omit<UnparsedFormSchema, "create_timestamp"> = {
    title: template.title,
    form_type: "custom",
    form_schema_id: template.id,
    form_schema_revision: template.revision,
    form_json_schema: JSON.stringify(jsonSchema),
    form_ui_schema: JSON.stringify(uiSchema),
  };

  return {
    url: apiHostAndPath(`/api/notes/v1/forms/schema/${template.id}`),
    method: "PUT",
    body,
  };
};

const transformResponse = (
  response: UnparsedNoteSchema | UnparsedFormSchema,
): NoteOrFormTemplate => {
  if (response.hasOwnProperty("note_json_schema")) {
    const unparsedNoteSchema = response as UnparsedNoteSchema;
    return {
      id: unparsedNoteSchema.note_schema_id,
      type: "note",
      title: unparsedNoteSchema.title ?? "",
      revision: unparsedNoteSchema.note_schema_revision,
      elements: convertTemplateToV2({
        jsonSchema: JSON.parse(unparsedNoteSchema.note_json_schema),
        uiSchema: JSON.parse(unparsedNoteSchema.note_ui_schema),
      }),
    };
  }

  const unparsedFormSchema = response as UnparsedFormSchema;
  return {
    id: unparsedFormSchema.form_schema_id,
    type: "form",
    formType: unparsedFormSchema.form_type,
    title: unparsedFormSchema.title ?? "",
    revision: unparsedFormSchema.form_schema_revision,
    elements: convertTemplateToV2({
      jsonSchema: JSON.parse(unparsedFormSchema.form_json_schema),
      uiSchema: JSON.parse(unparsedFormSchema.form_ui_schema),
    }),
  };
};

export const notesAndFormsTemplatesApi = createApi({
  reducerPath: "notesAndFormsTemplates",
  baseQuery: fetchBaseQuery({
    credentials: "include",
  }),
  tagTypes: ["Template", "Template-List"],
  endpoints: (builder) => ({
    getTemplate: builder.query<
      NoteOrFormTemplate,
      {
        type: TemplateType;
        id: number;
      }
    >({
      query: ({ type, id }) => {
        switch (type) {
          case "note":
            return apiHostAndPath(
              `/api/notes_secure_data/v1/notes/schema/${id}`,
            );
          case "form":
            return apiHostAndPath(`/api/notes/v1/forms/schema/${id}`);
          case "assessment":
            throw new Error(
              `Querying assessment templates is not supported yet`,
            );
        }
      },

      providesTags: ["Template"],
      transformResponse,
    }),

    listAllTemplates: builder.query<
      NoteOrFormTemplate[],
      {
        type: TemplateType;
      }
    >({
      query: ({ type }) => {
        switch (type) {
          case "note":
            return apiHostAndPath(`/api/notes_secure_data/v1/notes/schema/`);
          case "form":
            return apiHostAndPath(`/api/notes/v1/forms/schema/`);
          case "assessment":
            throw new Error(
              `Querying assessment templates is not supported yet`,
            );
        }
      },
      transformResponse: (response: any) => response?.map(transformResponse),
      providesTags: ["Template-List"],
    }),

    saveTemplate: builder.mutation<
      NoteOrFormTemplate,
      SaveTemplateMutationPayload
    >({
      query: (payload) => {
        if (payload.requestType === "create") {
          switch (payload.template.type) {
            case "note":
              return getCreateNoteSchemaQuery(payload.template);
            case "form":
              return getCreateFormSchemaQuery(payload.template);
            case "assessment":
              throw new Error(
                `Creating assessment templates is not supported yet`,
              );
          }
        }

        switch (payload.template.type) {
          case "note":
            return getUpdateNoteSchemaQuery(payload.template);
          case "form":
            return getUpdateFormSchemaQuery(payload.template);
          case "assessment":
            throw new Error(
              `Updating assessment templates is not supported yet`,
            );
        }
      },

      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        if (payload.requestType === "create") {
          return;
        }
        const { type, id, ...patch } = payload.template;
        const patchResult = dispatch(
          notesAndFormsTemplatesApi.util.updateQueryData(
            "getTemplate",
            { type, id },
            (draft) => {
              Object.assign(draft, patch);
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },

      transformResponse,
    }),
  }),
});

export const {
  useGetTemplateQuery,
  useListAllTemplatesQuery,
  useSaveTemplateMutation,
} = notesAndFormsTemplatesApi;
