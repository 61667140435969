import type { TNotesType } from "components/Dashboard/DoctorDashboard/notes-and-forms/types";
import { getErrorDetail } from "components/utility/utils";
import { fetchOrchidAPI } from "utils/api";
import getNoteOrFormSchemaDB from "./utils/getNoteOrFormSchemaDB";

/**
 * Query schemas
 */
export default async function querySchemas<T extends TNotesType>(
  InputType: T,
): Promise<
  (T extends "note"
    ? NoteSchema
    : T extends "assessment"
    ? AssessmentSchema
    : FormSchema)[]
> {
  const type = InputType === "assessment" ? "form" : InputType;
  if (!type) {
    throw new Error("need note or form type");
  }

  const res = await fetchOrchidAPI(
    `/api/${getNoteOrFormSchemaDB(type)}/v1/${type}s/schema/`,
  );

  if (!res.ok) {
    throw new Error(
      `failed to fetch schemas: ${getErrorDetail(await res.text())}`,
    );
  }

  const raw = await res.json();
  if (!raw) {
    return [];
  }

  const uiField = `${type}_ui_schema`;
  const jsonField = `${type}_json_schema`;

  return raw.map((el) => ({
    ...el,
    [uiField]: !el[uiField] ? el[uiField] : JSON.parse(el[uiField]),
    [jsonField]: !el[jsonField] ? el[jsonField] : JSON.parse(el[jsonField]),
  }));
}
