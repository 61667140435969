import { DEFAULT_TIMEZONE } from "constants/defaultTimezone";
import moment from "moment-timezone";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import queryUserInfo from "services/queryUserInfo";
import { setAmplitudeUser } from "utils/amplitude";
import { fetchOrchidAPI } from "utils/api";
import getLocalTimeZone from "utils/getLocalTimeZone";

export const UserContext = createContext<{
  user: User | null | undefined;
  defaultUserTimezone: TimeZone;
  updateUserInfo: () => Promise<User | null>;
}>({
  user: undefined,
  defaultUserTimezone: DEFAULT_TIMEZONE,
  updateUserInfo: () => Promise.resolve(null),
});

async function renewOrchidAccessToken(onError?: () => any) {
  const result = await fetchOrchidAPI("/api/login/v1/renew_access_token", {
    method: "POST",
  });
  if (!result.ok) {
    console.log("failed to renew access token");
    return onError && onError();
  }
  return true;
}

async function renewGuardianAccessToken(onError?: () => any) {
  const result = await fetchOrchidAPI(
    "/api/users/v1/minors/renew_current_access",
    {
      method: "POST",
    },
  );
  if (!result.ok) {
    console.log("failed to renew guardian access token");
    return onError && onError();
  }
  return true;
}

type Props = { children: ReactNode };

export const UserContextProvider = ({ children }: Props) => {
  // when user is null meaning that the user has successfully logged in once, but failed to renew token afterwards
  const [user, setUser] = useState<User | null | undefined>(undefined);

  const onRenewFailure = useCallback(() => {
    setUser(null);
  }, []);

  // fallback to browser timezone
  const defaultUserTimezone = useMemo(() => {
    return user?.timezone && moment.tz.names().includes(user.timezone)
      ? user.timezone
      : getLocalTimeZone();
  }, [user]);

  const updateUserInfo = useCallback(async () => {
    try {
      const user = await queryUserInfo();

      // Until we sign a BAA with Amplitude, we will only identify events coming from pros.
      if (user && user.user_type === "orchid_pro") {
        setAmplitudeUser(user.sub, {
          firstName: user.first_name,
          lastName: user.last_name,
          fullName:
            user.first_name && user.last_name
              ? `${user.first_name} ${user.last_name}`
              : undefined,
          email: user.email,
          businessName: user.business_entity_name,
          userType: user.user_type,
          isTestAccount: user.is_test_user,
        });
      }

      setUser((prevUser) => {
        const currentUser =
          new Date(user?.modify_datetime || 0) >
          new Date(prevUser?.modify_datetime || 0)
            ? user
            : prevUser;
        return currentUser || null;
      });
      return user;
    } catch (error) {
      console.error(error);
    }
    return null;
  }, []);

  useEffect(() => {
    (async () => {
      const user = await updateUserInfo();
      if (!user) {
        if (await renewOrchidAccessToken()) await updateUserInfo();
      }
    })();
  }, [onRenewFailure, updateUserInfo]);

  useEffect(() => {
    if (!user) return;

    async function checkAndRenewAccessTokens(user: User) {
      // token would expire soon, renew it
      if ((user.token_exp - 90) * 1000 < new Date().getTime()) {
        if (await renewOrchidAccessToken(onRenewFailure))
          await updateUserInfo();
      }

      if (
        user.guardian_token_exp &&
        (user.guardian_token_exp - 90) * 1000 < new Date().getTime()
      ) {
        if (await renewGuardianAccessToken(onRenewFailure))
          await updateUserInfo();
      }
    }

    // check expiry every minute, timers in browser are not very reliable
    const userSessionTimer = setInterval(
      () => checkAndRenewAccessTokens(user),
      60000,
    );

    // clean up
    return () => {
      return userSessionTimer && clearInterval(userSessionTimer);
    };
  }, [user, updateUserInfo, onRenewFailure]);

  return (
    <UserContext.Provider
      value={{
        user,
        defaultUserTimezone,
        updateUserInfo,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
