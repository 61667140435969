import { configureStore } from "@reduxjs/toolkit";

import calendarSidebarReducer from "./calendarSidebarSlice";
import { schedulerApi } from "./services/scheduler";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { notesCustomApi } from "./services/notesCustomApi";
import { notesAndFormsTemplatesApi } from "notes-and-forms-v2/store/notesAndFormsTemplatesApi";

export const store = configureStore({
  reducer: {
    calendarSidebar: calendarSidebarReducer,
    [schedulerApi.reducerPath]: schedulerApi.reducer,
    [notesCustomApi.reducerPath]: notesCustomApi.reducer,
    [notesAndFormsTemplatesApi.reducerPath]: notesAndFormsTemplatesApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      schedulerApi.middleware,
      notesCustomApi.middleware,
      notesAndFormsTemplatesApi.middleware,
    ),
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
