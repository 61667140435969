export function apiHostAndPath(path: string): string {
  if (window.location.hostname.endsWith(".secureportal.health")) {
    if (path.startsWith("/instant_messaging/ws")) {
      return `wss://${window.location.hostname}${path}`;
    } else {
      return `https://${window.location.hostname}${path}`;
    }
  }

  const LOCALHOST_API_HOSTNAME = "orchidsquirrel.com";
  // const LOCALHOST_API_HOSTNAME = "orchidstage.com";

  const remoteHostname =
    process.env?.NODE_ENV === "development"
      ? LOCALHOST_API_HOSTNAME
      : window.location.hostname;
  const hostname = remoteHostname.startsWith("www.")
    ? remoteHostname.substring(4)
    : remoteHostname;
  if (
    path.startsWith("/api/notes_secure_data/") ||
    path.startsWith("/api/static_drive/")
  ) {
    return `https://n42.${hostname}${path}`;
  } else if (path.startsWith("/api/login")) {
    return `https://login.${hostname}${path}`;
  } else if (path.startsWith("/api/drive_data")) {
    return `https://drive-data.${hostname}${path}`;
  } else if (path.startsWith("/api/drive")) {
    return `https://drive.${hostname}${path}`;
  } else if (path.startsWith("/api/users")) {
    return `https://login.${hostname}${path}`;
  } else if (path.startsWith("/api/pubnub_grant")) {
    return `https://login.${hostname}${path}`;
  } else if (path.startsWith("/api/reporting")) {
    return `https://report.${hostname}${path}`;
  } else if (path.startsWith("/instant_messaging/ws")) {
    return `wss://ws.${hostname}${path}`;
  } else {
    return `https://api.${hostname}${path}`;
  }
}

export async function fetchOrchidAPI(
  resource: string,
  init: RequestInit = {},
): Promise<Response> {
  try {
    const headers = new Headers(init.headers ? init.headers : {});
    if (!headers.has("Content-Type"))
      headers.set("Content-Type", "application/json");

    const initDefaultToJsonBody = {
      ...init,
      headers: headers,
      credentials: init.credentials ? init.credentials : "include",
      mode: init.credentials ? init.mode : "cors",
    };
    const response = await fetch(
      apiHostAndPath(resource),
      initDefaultToJsonBody,
    );
    return response;
  } catch (err: any) {
    if (err.name === "AbortError") throw err;
    const init = { status: 503, statusText: "ServiceUnavailable" };
    const networkErr = new Response(err, init);
    return networkErr;
  }
  // return fetch(apiHostAndPath(resource), { ...init, credentials: "include", mode: 'cors' });
}
