import {
  CheckboxesElement,
  ParagraphElement,
  RadiosElement,
  ShortAnswerElement,
  TemplateElement,
  TextOnlyElement,
} from "notes-and-forms-v2/NotesAndForms.types";

type RequireableElement =
  | TextOnlyElement
  | ShortAnswerElement
  | RadiosElement
  | CheckboxesElement
  | ParagraphElement;

export default function canElementBeRequired(
  templateElement: TemplateElement,
): templateElement is RequireableElement {
  switch (templateElement.type) {
    case "text-only":
    case "short-answer":
    case "radios":
    case "checkboxes":
    case "paragraph":
      return true;
    case "radios-grid":
    case "section":
    case "unknown":
      return false;
  }
}
