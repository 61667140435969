import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Card, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReactComponent as DownArrow } from "assets/svg/partner-with-orchid/down-arrow.svg";
import ModalLayout from "components/modal/modal-layout/ModalLayout";
import LazyImage from "components/lazy-image";
import CustomAccordion from "components/custom-accordion/CustomAccordion";
import PartnerWithOrchidMobile from "./PartnerWithOrchidMobile";
import Footer from "components/footer/Footer";
import FullWidthImage from "components/full-width-image";
import requestDemo from "services/requestDemo";
import { useTimeOnPageContext } from "contexts/time-on-page";
import { usePhonePortraitContext } from "contexts/phone-portrait-context";

import { Helmet } from "react-helmet";

import { AmplitudePageName, track } from "utils/amplitude";

import styles from "./PartnerWithOrchid.module.scss";

const AMPLITUDE_PAGE_NAME: AmplitudePageName = "partner-with-orchid";

const LANDING_URL = "/img/partner-with-orchid/landing.png";
const BENEFIT_1_URL = "/img/partner-with-orchid/benefit-1.png";
const BENEFIT_2_URL = "/img/partner-with-orchid/benefit-2.png";
const BENEFIT_3_URL = "/img/partner-with-orchid/benefit-3.png";
const AMHC_COFOUNDER_URL = "/img/partner-with-orchid/amhc-cofounder.png";
const CASE_STUDY_PDF_URL = "/pdf/AMHC_X_Orchid_Case_Study.pdf";

export const stats = [
  {
    id: 0,
    title: "$120,000",
    text: "Saved Annually on <br /> Marketing & Support",
  },
  {
    id: 1,
    title: "2400",
    text: "Admin Hours <br /> Saved Annually",
  },
  {
    id: 2,
    title: "1040",
    text: "Subsidized <br /> Therapy Sessions Funded",
  },
  {
    id: 3,
    title: "98%",
    text: "Campaign <br /> Conversion Rate",
  },
];

export const questions = [
  {
    id: "0",
    question: "What is Orchid?",
    answer: `<p>Orchid is an innovative electronic health record built by clinicians for clinicians. We’re proud to be a privately owned company, <a href="https://www.ycombinator.com/companies/orchid" target="_blank">backed by Y Combinator.</a></p>

    <p>Not only is Orchid’s EHR the most affordable private practice management software on the market. We also lead the way with cutting-edge features like AI-driven insights, measurement-based mental health care, and robust automations that make client no-shows ancient history.</p>

    <p>Founded by mental health professionals, Orchid makes it a priority to anticipate and evolve with your practice’s needs — making us the perfect partner for private practice clinicians seeking efficiency, reliability, and cost-effectiveness.</p>

    <p><a href="https://orchid.exchange/about-us" target="_blank">Read more about Orchid’s mission</a></p>`,
  },
  {
    id: "1",
    question: "What is AMHC?",
    answer: `<p>The Asian Mental Health Collective (AMHC) is a non-profit organization with a mission to normalize and de-stigmatize mental health care within the AAPI community through advocacy, resource sharing, and other initiatives.</p>

    <p>In partnership with Orchid, AMHC’s Lotus Therapy Fund program subsidized more than $130,000 in therapy sessions, helping to make mental health care more accessible for more AAPI clients around the U.S.</p>`,
  },
  {
    id: "2",
    question: "How can my organization partner with Orchid?",
    answer: `<p>Orchid offers a variety of services in partnership with nonprofits and other mental health organizations. Whether you need exclusive business analytics, AI automations, email marketing support, access to a growing professional network, or something else bespoke and efficient, we want to hear from you about how Orchid can help further your mission.</p>

    <p>Email us at <b>info@orchid.health</b> to start the conversation!</p>`,
  },
  {
    id: "3",
    question: "How can I sign up for Orchid as a clinician?",
    answer: `<p>The easiest way to sign up for Orchid is to get free 30-day access to our Core subscription plan. At any point during that 30-day free trial, you’ll be able to choose the right paid plan for you.</p>

    <p><a href="https://orchid.exchange/pricing" target="_blank">Get your free trial</a></p>`,
  },
  {
    id: "4",
    question: "How do I sign up for Orchid as a client?",
    answer: `<p>If you’re searching for mental health care, you can <a href="https://orchid.exchange/member-register" target="_blank">register for Orchid here.</a>, then start browsing our professional directory to schedule your first session with one of our community’s licensed professionals. 
    </p>

    <p><a href="https://orchid.exchange/professional-search" target="_blank">Check out the Orchid Directory</a></p>`,
  },
  {
    id: "5",
    question: "Is Orchid HIPAA compliant?",
    answer: `<p>Absolutely. Orchid prioritizes HIPAA compliance, security, and confidentiality in every aspect of our product.</p>

    <p><a href="https://orchid.exchange/ehr-security" target="_blank">Learn more about Orchid security measures</a></p>`,
  },
];

const PartnerWithOrchid = () => {
  const { setPage } = useTimeOnPageContext();
  useEffect(() => setPage(AMPLITUDE_PAGE_NAME), [setPage]);

  const { isPhonePortrait } = usePhonePortraitContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [showEmailModal, setShowEmailModal] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [validated, setValidated] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = CASE_STUDY_PDF_URL;
    link.download = "AMHC_X_Orchid_Case_Study.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const enterEmailToReceiveCaseStudy = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }

    try {
      await requestDemo(firstName, "CaseStudy", email);
      handleDownload();
      setShowEmailModal(false);
    } catch (err) {
      console.log(err);
      setError(err);
    } finally {
    }
  };

  return (
    <>
      <Helmet>
        <title>Partner with Orchid</title>
        <meta
          name="description"
          content="Learn more about AMHC and the benefits of partnering with Orchid."
        />
        <meta property="og:title" content="Partner with Orchid" />
        <meta
          property="og:description"
          content="Learn more about AMHC and the benefits of partnering with Orchid."
        />

        <meta name="twitter:title" content="Orchid EHR" />
        <meta
          name="twitter:description"
          content="Learn more about AMHC and the benefits of partnering with Orchid."
        />
      </Helmet>
      {showEmailModal && (
        <ModalLayout
          size={"md"}
          show={showEmailModal}
          onHide={() => {
            setShowEmailModal(false);
          }}
          error={error}
          contentClassName={styles["email-modal__content"]}
          showHeaderDivider={true}
          Header={"Download the Partnership Case Study"}
          headerClass={styles["email-modal__header"]}
          bodyClass={styles["email-modal__body"]}
          Footer={
            <>
              <Button
                variant={"outline-primary"}
                onClick={() => setShowEmailModal(false)}
              >
                No Thanks
              </Button>
              <Button form={"download-form"} type={"submit"}>
                Download Case Study
              </Button>
            </>
          }
          footerClass={styles["email-modal__footer"]}
        >
          <div>
            <b>AMHC Partnership Case Study:</b>
          </div>

          <p>Learn About How Orchid Can Save You Time & Money</p>
          <Form
            noValidate
            validated={validated}
            id={"download-form"}
            onSubmit={enterEmailToReceiveCaseStudy}
          >
            <Form.Group controlId="fist_name">
              <Form.Control
                required
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder={"Enter First Name"}
              />
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Control
                required
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={"Enter Email address"}
              />
            </Form.Group>
          </Form>
        </ModalLayout>
      )}
      {isPhonePortrait ? (
        <PartnerWithOrchidMobile />
      ) : (
        <div>
          <div className={styles["landing"]}>
            <Container className={styles["container"]}>
              <div className={styles["landing__left"]}>
                <div className={styles["landing__title"]}>
                  Make a bigger impact <br /> with Orchid
                </div>
                <div className={styles["landing__text"]}>
                  Save your organization more time, money, and lives <br />
                  with Orchid’s customized partnership solutions.
                </div>
                <div className={styles["landing__button"]}>
                  <Button
                    className={styles["action"]}
                    as={Link}
                    target={"_blank"}
                    rel={"noopener noreferrer"}
                    to={"/about-us"}
                    onClick={() => {
                      track(
                        AMPLITUDE_PAGE_NAME,
                        "partner-with-orchid-button",
                        "click",
                      );
                    }}
                  >
                    Partner with Orchid
                  </Button>
                </div>
              </div>
              <div className={styles["landing__right"]}>
                <LazyImage src={LANDING_URL} width={695} height={588} fluid />
              </div>
            </Container>
          </div>

          <div className={styles["benefits"]}>
            <h1 className={styles["benefits__title"]}>
              Get more when you partner with Orchid
            </h1>
            <div className={styles["benefits__text"]}>
              More than just a top-notch EHR for private practice clinicians,
              Orchid is your mental health organization’s <br /> personalized
              solution for achieving better results with fewer resources.
            </div>

            <Row className={styles["benefit-row"]}>
              <Col className={styles["benefit-row__left-img"]}>
                <FullWidthImage src={BENEFIT_1_URL} fluid />
              </Col>
              <Col className={styles["benefit-row__right-text"]}>
                <div className={styles["benefit-row__title"]}>
                  Save more annually
                </div>
                <div className={styles["benefit-row__content"]}>
                  Let us handle the time-consuming administrative work that’s
                  holding <br /> you back from making a bigger impact on your
                  community. 
                </div>
              </Col>
            </Row>

            <Row className={styles["benefit-row"]}>
              <Col className={styles["benefit-row__left-text"]}>
                <div className={styles["benefit-row__title"]}>
                  Convert more humans to your cause
                </div>
                <div className={styles["benefit-row__content"]}>
                  Need support with conversion marketing, analytics insights, or
                  customer care? You name it, we can offer you bespoke
                  automations and hands-on assistance from a dedicated team.
                </div>
              </Col>
              <Col className={styles["benefit-row__right-img"]}>
                <FullWidthImage src={BENEFIT_2_URL} fluid />
              </Col>
            </Row>

            <Row className={styles["benefit-row"]}>
              <Col className={styles["benefit-row__left-img"]}>
                <FullWidthImage src={BENEFIT_3_URL} fluid />
              </Col>
              <Col className={styles["benefit-row__right-text"]}>
                <div className={styles["benefit-row__title"]}>
                  Access an exclusive mental health <br /> network
                </div>
                <div className={styles["benefit-row__content"]}>
                  Enrich your community by connecting with our growing network
                  of
                  <br />
                  clinicians and clients.
                </div>
              </Col>
            </Row>
          </div>

          <div className={styles["case-study"]}>
            <Container className={styles["case-study__intro"]}>
              <h2 className={styles["case-study__discover"]}>
                Discover how partner organizations have benefited from Orchid so
                far…
              </h2>
              <div>
                <DownArrow />
              </div>
            </Container>

            <Container className={styles["case-study__amhc-outer"]}>
              <Container className={styles["case-study__amhc-inner"]}>
                <div className={styles["case-study__case-study"]}>
                  CASE STUDY:
                </div>
                <div className={styles["case-study__amhc"]}>
                  Asian Mental Health Collective
                </div>
                <div className={styles["case-study__learn"]}>
                  Learn how the non-profit AMHC saves more time & shows up for
                  their community through <br /> their partnership with Orchid.
                </div>
              </Container>

              {/* <a
                href={CASE_STUDY_PDF_URL}
                download="AMHC_X_Orchid_Case_Study.pdf"
              > */}
              <Button
                className={styles["action"]}
                onClick={() => {
                  setEmail("");
                  setFirstName("");
                  setValidated(false);
                  setError(undefined);

                  setShowEmailModal(true);
                  track(
                    AMPLITUDE_PAGE_NAME,
                    "download-case-study-button",
                    "click",
                  );
                }}
              >
                Download the Case Study
              </Button>
              {/* </a> */}
            </Container>

            <Card className={styles["quote-card"]}>
              <Row>
                <div className={styles["quote-card__left"]}>
                  <FullWidthImage
                    className={styles["quote-card__img"]}
                    src={AMHC_COFOUNDER_URL}
                    fluid
                  />
                </div>

                <Col className={styles["quote-card__right"]}>
                  <div className={styles["quote-card__box"]}>
                    <div className={styles["quote-card__text"]}>
                      <span>“ </span> Orchid has shown a commitment to making
                      mental healthcare <br /> more accessible by offering
                      clinicians a more robust and affordable <br /> platform.
                      When choosing a partner, it was important to find a <br />{" "}
                      company that was dedicated to supporting what we believed
                      in: the <br /> desire to give back to the mental health
                      community at large.<span> ”</span>
                    </div>
                    <div className={styles["quote-card__citation"]}>
                      — Christopher Vo, AMHC Co-Founder
                    </div>
                  </div>
                </Col>
              </Row>
            </Card>
          </div>

          <div className={styles["challenge"]}>
            <div className={styles["challenge__box"]}>
              <p className={styles["challenge__title"]}>The Challenge</p>

              <p className={styles["challenge__text"]}>
                When the non-profit organization Asian Mental Health Collective
                (AMHC) funded a whopping $130,000 worth of free therapy sessions
                for AAPI clients through their Lotus Therapy Fund (LTF), they
                needed a streamlined way to connect AMHC providers with
                grantees. 
              </p>
            </div>

            <div className={styles["challenge__box"]}>
              <div className={styles["challenge__title"]}>The Solution</div>

              <p className={styles["challenge__text"]}>
                Orchid rose to that challenge: Powered by our all-in-one EHR
                platform, over 100 LTF grantees received free therapy from AMHC
                providers through Orchid and set out on their mental health
                journeys together.  And we didn’t stop there: Partnering with
                Orchid also helped AMHC reduce overhead and grow their network,
                thanks to Orchid’s bespoke analytics reports and conversion
                marketing campaign.
              </p>
            </div>

            <div className={styles["result-card"]}>
              <div className={styles["result-card__box"]}>
                <div className={styles["challenge__title"]}>The Result</div>

                <div className={styles["challenge__text"]}>
                  Orchid saved AMHC time, money, and headaches, so they could
                  keep up the hard work of connecting the AAPI community with
                  life-saving mental health care. 
                </div>
              </div>

              <Container className={styles["stats-container"]}>
                <Row className={styles["stats-row"]}>
                  {stats.map((stat) => (
                    <Col key={stat.id} className={styles["stats-col"]}>
                      <Card className={styles["stat"]}>
                        <div className={styles["stat__title"]}>
                          {stat.title}
                        </div>
                        <div
                          className={styles["stat__text"]}
                          dangerouslySetInnerHTML={{ __html: stat.text }}
                        />
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Container>
            </div>

            {/* <a
              href={CASE_STUDY_PDF_URL}
              download="AMHC_X_Orchid_Case_Study.pdf"
            > */}
            <Button
              className={styles["action"]}
              onClick={() => {
                setEmail("");
                setFirstName("");
                setValidated(false);
                setError(undefined);

                setShowEmailModal(true);
                track(
                  AMPLITUDE_PAGE_NAME,
                  "download-case-study-button",
                  "click",
                );
              }}
            >
              Download the Case Study
            </Button>
            {/* </a> */}
          </div>

          <div className={styles["faq"]}>
            <h1 className={styles["faq__title"]}>
              Questions? We’ve got answers.
            </h1>

            {questions.map(({ id, question, answer }, idx) => (
              <div className={styles["faq__text"]}>
                <CustomAccordion
                  key={id}
                  id={id}
                  index={idx}
                  question={question}
                  answer={answer}
                  amplitudePageName={AMPLITUDE_PAGE_NAME}
                />
              </div>
            ))}

            <div className={styles["more-question"]}>
              <div className={styles["more-question__title"]}>
                Got more questions about Orchid?
              </div>
              <div>
                <Button as={Link} to={"/orchid-ehr-faq"}>
                  Read the rest of our FAQs
                </Button>
              </div>
            </div>
          </div>

          <div className={styles["get-power"]}>
            <Container className={styles["get-power__container"]}>
              <div className={styles["get-power__title"]}>
                Get the power of Orchid AI today.
              </div>
              <div className={styles["get-power__text"]}>
                Transform your practice with increased efficiency, deeper
                insights, and improved client outcomes.
              </div>
              <div>
                <Button
                  size={"lg"}
                  className={styles["action"]}
                  as={"a"}
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                  href={"https://calendly.com/hey-orchid/orchid-walkthrough"}
                  onClick={() => {
                    track(
                      AMPLITUDE_PAGE_NAME,
                      "sign-up-for-demo-button",
                      "click",
                    );
                  }}
                >
                  Get your free demo
                </Button>
              </div>
            </Container>
          </div>

          <Footer AMPLITUDE_PAGE_NAME={AMPLITUDE_PAGE_NAME} />
        </div>
      )}
    </>
  );
};

export default PartnerWithOrchid;
