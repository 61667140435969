import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import { Provider as ReduxProvider } from "react-redux";
import App from "./App";

import AppContextProvider from "./contexts";
import * as serviceWorker from "./serviceWorker";

import { store } from "./store/store";

ReactDOM.render(
  <BrowserRouter>
    <AppContextProvider>
      <ReduxProvider store={store}>
        <App />
      </ReduxProvider>
    </AppContextProvider>
  </BrowserRouter>,

  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

navigator.serviceWorker?.register(
  `${process.env.PUBLIC_URL}/drive/orchid-drive-download-service-worker.js`,
);
